
import { IonPage, IonHeader, IonContent, IonFooter,
  onIonViewDidEnter,
  onIonViewWillEnter, } from "@ionic/vue";
import Header from "@/components/header.vue";
import { skipRouter } from "@/router";
import { ref } from "vue";
import { feedbackCommit } from "@/service/member";
import { openTips, openModal } from "@/utils/alert";
import { userinfo, checklogin } from "@/utils/usermodule";
import { local } from "@/utils/storage.service";

export default {
  name: "Feedback",
  components: { Header, IonHeader, IonContent, IonPage, IonFooter },
  setup() {
    const comment: any = ref("");
    const loginuser = local.get("loginuser");
    console.log(loginuser);
    //检测是否登录
    checklogin();

    const submitComment = async () => {
      if (loginuser == null || loginuser.islogin != "1") {
        return;
      }
      if (comment.value === "") {
        openTips("留言内容为空!");
        return;
      }
      if (comment.value.length > 200) {
        openTips("留言内容最多为200字!");
        return;
      }
      const result = await feedbackCommit({
        UserId: loginuser.userid,
        UserName: loginuser.username,
        FeedBackType: 10,
        CooperateMode: "4",
        Remark: comment.value,
      });
      if (result) {
        openModal("留言成功!");
        comment.value = "";
      }
    };

    return { skipRouter, comment, submitComment };
  },
};
